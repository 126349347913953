import React from "react"

import Layout from "../components/layout"
import {Link} from "gatsby"
import SEO from "../components/seo"
import featureImage from "../../static/images/screenshot-A.png";
import styled from "@emotion/styled"

import { useStaticQuery, graphql } from "gatsby"
import AppIcon from "../../static/images/AppIcon@2x.png"
import Title from "../../static/images/Title.svg"

import CarouselInstantPreview from "../../static/images/Carousel-InstantPreview@2x.png"
import InstantPreviewHeading from "../../static/images/InstantPreviewHeading.svg"

import CarouselSmartGrouping from "../../static/images/Carousel-Grouping@2x.png"
import SmartGroupingHeading from "../../static/images/SmartGroupingHeading.svg"

import CarouselWorksWithOthers from "../../static/images/Carousel-Integration@2x.png"
import WorksWithOthersHeading from "../../static/images/WorksWithOthersHeading.svg"

import CarouselInstantDecoding from "../../static/images/Carousel-InstantDecoding@2x.png"
import InstantDecodingHeading from "../../static/images/InstantDecodingHeading.svg"

import SashimiProTitle from "../../static/images/SashimiPro.svg"

import PriceBadgeBackground from "../../static/images/PriceBadge.svg"

import ComparisonMatrix from "../../static/images/ComparisonMatrix.svg"

import BuyButton from "../../static/images/BuyButtonNormal.svg"
import BuyButtonHover from "../../static/images/BuyButtonHover.svg"
import BuyButtonActive from "../../static/images/BuyButtonActive.svg"

import { Flex, Box } from "reflexbox"

const AppIconImage = styled.img`
    width: 400px;
`

const CarouselInstantPreviewImage = styled.img`
    width: 657px;
`

const CarouselSmartGroupingImage = styled.img`
    width: 591px;
`

const CarouselWorksWithOthersImage = styled.img`
    width: 563px;
    padding: 30px;
`

const CarouselInstantDecodingImage = styled.img`
    width: 454px;
`

const Hero = styled.div`
    text-align: center;
    padding: 180px 0 100px 0;
    background-color: #fdfaff;
    background-image: radial-gradient(50% 86%, #2C2C2C 26%, #1B1B1B 86%);
    box-shadow: 0px 10px 25px 0px rgba(0,0,0,0.2);
`

const TitleImage = styled.img`
    width: 425px;
    display: block;
    margin-left: auto;
    margin-right: auto;
`

const Subtitle = styled.h2`
    font-size: 40px;
    font-weight: 400;
    color: white;
    opacity: 66%;
    margin-bottom: 0px;
    animation: reveal-subtitle 1s ease;
    animation-fill-mode: backwards;

    @keyframes reveal-subtitle {
        0%   {opacity: 0%;}
        100% {opacity: 66%;}
    }
`

const Subsubtitle = styled(Subtitle)`
    margin-top: 10px;
    font-size: 30px;
    opacity: 33%;
    animation: reveal-subsubtitle 2s ease;
    animation-fill-mode: backwards;

    @keyframes reveal-subsubtitle {
        0%   {opacity: 0%;}
        100% {opacity: 33%;}
    }
`
const FeatureImage = styled.img`
    margin-top: 100px;
    max-width: 80%;

    animation: reveal-feature-image 1s ease;
    animation-fill-mode: backwards;

    @keyframes reveal-feature-image {
        0%   {opacity: 0%;}
        100% {opacity: 100%;}
    }
`

const FeatureSection = styled.div`
    padding-top: 80px;
    padding-bottom: 80px;
`

const InstantPreviewSection = styled(FeatureSection)`
    background-image: radial-gradient(16% 134%, #556562 38%, #3E4745 100%);
`

const SmartGroupingSection = styled(FeatureSection)`
    background-image: radial-gradient(77% 132%, #82596F 18%, #5F4151 67%, #48323E 100%);
`

const WorksWithOthersSection = styled(FeatureSection)`
    background-image: radial-gradient(55% 183%, #2C5D73 40%, #0B3140 100%);
`

const InstantDecodingSection = styled(FeatureSection)`
    background-image: radial-gradient(67% 176%, #A49E49 63%, #605B2D 100%);
`

const ComparisonSection = styled(FeatureSection)`
    background-image: radial-gradient(58% 59%, #FFBF3D 11%, #FF9900 60%);
`

const ComparisonHeading = styled.h2`
    text-align: center;
`

const SalesPitch = styled.h2`
    text-transform: uppercase;
    color: white;
    font-size: 29px;
    text-align: center;
    padding-top: 0px;
    margin-top: 10px;
`

const InstantPreviewHeadingImage = styled.img`
    display: block;
    width: 439px;
`

const SmartGroupingHeadingImage = styled.img`
    display: block;
    text-align: right;
    margin-right: 47px;
    margin-left: auto;
    width: 309px;
`

const WorksWithOthersHeadingImage = styled.img`
    display: block;
    width: 353px;
    padding-left: 0px;
`

const InstantDecodingHeadingImage = styled.img`
    display: block;
    text-align: right;
    margin-right: 47px;
    margin-left: auto;
    width: 430px;
`

const FeatureBox = styled(Flex)`
    padding: 20px;
`

const FeatureImageBoxLeft = styled(Box)`
    text-align: right;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
`

const FeatureImageBoxRight = styled(Box)`
    text-align: left;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
`

const FeatureDescriptionBoxRight = styled(Box)`
    text-align: left;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
`

const FeatureDescriptionBoxLeft = styled(Box)`
    text-align: right;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 0;
    margin-left: auto;
`

const FeatureDescription = styled.p`
    font-size: 25px;
    color: #FFFFFF;
    line-height: 28px;
    max-width: 400px;
    margin-top: 20px;
`

const FeatureDescriptionRight = styled(FeatureDescription)`
    margin-right: auto;
    margin-left: 47px;
`

const FeatureDescriptionLeft = styled(FeatureDescription)`
    margin-right: 47px;
    margin-left: auto;
`

const SashimiProImage = styled.img`
    width: 237px;
    vertical-align: middle;
    padding-right: 15px;
`

const PriceBadge = styled.div`
    width: 110px;
    height: 110px;
    margin-top: auto;
    margin-bottom: auto;
    vertical-align: middle;
    text-align: center;
    color: white;
    font-weight: 700;
    background: url(${PriceBadgeBackground});
    background-repeat: none;
    vertical-align: middle;
    display: inline-block;
    
    &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 100%;
    }
`

const ComparisonMatrixContainer = styled.div`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
`

const ComparisonMatrixImage = styled.img`
    display: block;
    width: 340px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
`

const BuyLink = styled.a`
    display: block;
    margin-left: auto;
    margin-right: auto;
    background: url('${BuyButton}');
    background-repeat: none;
    width: 306px;
    height: 40px;

    &:hover {
        background: url('${BuyButtonHover}')    
    }
    &:active {
        background: url('${BuyButtonActive}')    
    }
`

const IndexPage = () => {
    const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                title
                description
                author
              }
            }
          }
        `
      )

    return <Layout>
        <SEO title={site.siteMetadata.title} />

        <Hero>
            <AppIconImage alt={"Sashimi"} src={AppIcon} />
            <TitleImage src={Title} alt="Sashimi (beta)" title="Sashimi" />
            <Subtitle>{site.siteMetadata.description}</Subtitle>
            <Subsubtitle>RAW, JPEG, PNG and more…</Subsubtitle>
            
            <FeatureImage alt={"Dashboard"} src={featureImage}/>
        </Hero>

        <InstantPreviewSection>
            <FeatureBox mx={-2} flexDirection='column'>
                <FeatureImageBoxLeft width={1/2} px={2}>
                    <CarouselInstantPreviewImage src={CarouselInstantPreview} />
                </FeatureImageBoxLeft>
                <FeatureDescriptionBoxRight width={1/2} px={2}>
                    <InstantPreviewHeadingImage src={InstantPreviewHeading} />
                    <FeatureDescriptionRight>
                        Plug your iPhone and browse your best shots 
                        directly on your Mac screen.
                    </FeatureDescriptionRight>
                </FeatureDescriptionBoxRight>
            </FeatureBox>
        </InstantPreviewSection>

        <SmartGroupingSection>
            <FeatureBox mx={-2} flexDirection='column'>
                <FeatureDescriptionBoxLeft width={1/2} px={2}>
                    <SmartGroupingHeadingImage src={SmartGroupingHeading} />
                    <FeatureDescriptionLeft>
                        Plug your iPhone and browse your best shots 
                        directly on your Mac screen.
                    </FeatureDescriptionLeft>
                </FeatureDescriptionBoxLeft>
                <FeatureImageBoxRight width={1/2} px={2}>
                    <CarouselSmartGroupingImage src={CarouselSmartGrouping} />
                </FeatureImageBoxRight>                
            </FeatureBox>
        </SmartGroupingSection>

        <WorksWithOthersSection  flexDirection='column'>
            <FeatureBox mx={-2} flexDirection='column'>
                <FeatureImageBoxLeft width={1/2} px={2}>
                    <CarouselWorksWithOthersImage src={CarouselWorksWithOthers} />
                </FeatureImageBoxLeft>
                <FeatureDescriptionBoxRight width={1/2} px={2}>
                    <WorksWithOthersHeadingImage src={WorksWithOthersHeading} />
                    <FeatureDescriptionRight>
                        Save your best images directly
                        to Photos, Lightroom or Capture One.
                    </FeatureDescriptionRight>
                </FeatureDescriptionBoxRight>
            </FeatureBox>
        </WorksWithOthersSection>

        <InstantDecodingSection>
            <FeatureBox mx={-2} flexDirection='row'>
                <FeatureDescriptionBoxLeft width={1/2} px={2}>
                    <InstantDecodingHeadingImage src={InstantDecodingHeading} />
                    <FeatureDescriptionLeft>
                        Crunching tons of image files in an instant.
                    </FeatureDescriptionLeft>
                </FeatureDescriptionBoxLeft>
                <FeatureImageBoxRight width={1/2} px={2}>
                    <CarouselInstantDecodingImage src={CarouselInstantDecoding} />
                </FeatureImageBoxRight>                
            </FeatureBox>
        </InstantDecodingSection>

        <ComparisonSection>
            <ComparisonHeading>
                <SashimiProImage src={SashimiProTitle} />
                <PriceBadge>
                    €10.99
                </PriceBadge>
            </ComparisonHeading>
            <BuyLink variant={'nav'} href={"/buy"} />
            <SalesPitch>Limited time offer!</SalesPitch>

            <ComparisonMatrixContainer>
                <ComparisonMatrixImage src={ComparisonMatrix} />
            </ComparisonMatrixContainer>
        </ComparisonSection>
    </Layout>
}

export default IndexPage
